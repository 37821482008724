import type { PathRouteProps } from "react-router-dom";

import ClaimsPage from "../pages/claims/ClaimsPage";
import HomePage from "../pages/home/HomePage";
import UnauthorizedPage from "../pages/UnauthorizedPage";

interface Props extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<Props> = [
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/claims",
    element: <ClaimsPage />
  },
  {
    path: "/not-authorized",
    element: <UnauthorizedPage />
  }
];

export const privateRoutes: Array<Props> = [
  {
    path: "/:id",
    element: <HomePage />
  }
];
