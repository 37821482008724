import { useEffect, useState } from "react";

import { Center, Spinner, useToast } from "@chakra-ui/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

import { UserInfo } from "../../../../../shared/models";
import { AppConfig } from "../../services/app-config-service";
import { useAppStore } from "../../store";

type PrivateRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
};

// const delay = (ms: number): Promise<void> => {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// };

const RequireAuth = ({ children, redirectTo = "/login" }: PrivateRouteProps) => {
  const clerk = useClerk();
  const user = useUser();
  const toast = useToast();
  const [hasCheckedUserInfo, setHasCheckedUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useAppStore((state) => [state.userInfo, state.setUserInfo]);

  useEffect(() => {
    if (!clerk.loaded) {
      return;
    }

    if (!user.isSignedIn) {
      setHasCheckedUserInfo(true);
      return;
    }

    if (user.isSignedIn && userInfo) {
      setHasCheckedUserInfo(true);
      return;
    }

    console.log({ isSignedIn: user.isSignedIn, userInfo: userInfo });

    if (user.isSignedIn && !userInfo) {
      const fetchUserInfo = async () => {
        const userInfoResponse = await fetch(`${AppConfig.apiUrl}/api/user-info`);

        const isAuthError = userInfoResponse.status == 403;

        if (!userInfoResponse.ok && !isAuthError) {
          toast({
            title: "Error!",
            description: "There was an issue calling the server",
            status: "error",
            position: "top",
            duration: 3000
          });
        }

        const userInfo = (await userInfoResponse.json()) as UserInfo;
        setUserInfo(userInfo);
        setHasCheckedUserInfo(true);
      };
      fetchUserInfo();
    }
  }, [clerk.loaded, user.isSignedIn, userInfo]);

  if (!hasCheckedUserInfo) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  const isAuthenticated = user.isSignedIn; // !!userInfo?.isApproved;
  const isAuthorized = isAuthenticated && userInfo && userInfo.isApproved;
  console.log({ isAuthenticated, userInfo });

  if (!isAuthorized && hasCheckedUserInfo) {
    return <Navigate to="not-authorized" />;
  }

  return isAuthenticated ? (children as React.ReactElement) : <Navigate to={redirectTo} />;
};

export default RequireAuth;
