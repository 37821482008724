import { useState, useEffect } from "react";

import { HStack, VStack, Text, Heading, Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { ClaimSummary } from "../../../../../shared/models";
import { AppConfig } from "../../services/app-config-service";

const ClaimsPage = () => {
  const [data, setData] = useState<ClaimSummary[]>([]);

  function fetchClaims() {
    fetch(`${AppConfig.apiUrl}/api/claims`)
      .then((res) => res.json())
      .then((data) => {
        const sortedClaims = data.claims;
        sortedClaims.reverse();
        setData(data.claims || []);
      });
  }

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    fetchClaims();
  }, []);

  return (
    <HStack w="full" h="calc(100vh - 170px)" alignItems="stretch" overflow={"hidden"}>
      <Helmet>
        <title>All Claims</title>
      </Helmet>
      <VStack id="claims-page" h="full" w="full" overflowY="auto">
        <Heading>All Claims</Heading>
        <VStack gap="6" maxW={600} alignItems="flex-start">
          {data.map((claim) => (
            <Box alignSelf="flex-start">
              <Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  Full Name:
                </Text>{" "}
                {claim.fullName}
              </Text>
              <Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  Policy Number:
                </Text>{" "}
                {claim.policyNumber}
              </Text>
              <Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  Claim Location:
                </Text>{" "}
                {claim.location}
              </Text>
              <Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  Claim Summary:
                </Text>{" "}
                {claim.summaryOfIncident}
              </Text>
              <Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  Claim Police Report File?
                </Text>{" "}
                {claim.policeReportIsFiled ? "Yes" : "No"}
              </Text>
              <Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  Claim Police Report Number:
                </Text>{" "}
                {claim.policeReportNumber}
              </Text>
            </Box>
          ))}
        </VStack>
      </VStack>
    </HStack>
  );
};

export default ClaimsPage;
