import { Flex, Box, Text } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

const SignInPage = () => {
  const user = useUser();

  if (!user.isSignedIn) {
    return <Navigate to="/signin" />;
  }

  return (
    <Flex height={"100dvh"} justify={"center"} align={["top", "center"]}>
      <Box>
        <Text>User "{user.user?.primaryEmailAddress?.emailAddress || ""}" is not authorized.</Text>
        <Text>Please contact your administrator.</Text>
      </Box>
    </Flex>
  );
};

export default SignInPage;
