class AppConfigService {
  private hideWipFeatures = import.meta.env.VITE_HIDE_WIP_FEATURES?.toUpperCase() === "TRUE";

  public get isDev(): boolean {
    return import.meta.env.MODE === "development";
  }

  public get apiUrl(): string {
    console.log(import.meta.env.MODE);
    return "";
  }

  public get shouldHideWipFeatures(): boolean {
    return this.hideWipFeatures;
  }

  public get canShowWipFeatures(): boolean {
    return !this.hideWipFeatures;
  }
}

export const AppConfig = new AppConfigService();
