"use client";

import { ReactNode, useEffect } from "react";

import { Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import Body from "./Body";
import Footer from "./Footer";
import NavBar from "./NavBar";

type LayoutProps = {
  bodyPadding?: number[];
  bgColor?: string;
  children: ReactNode;
};

export default function Layout({ children, bodyPadding, bgColor }: LayoutProps) {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const location = useLocation();
  const bodyPaddingToUse = bodyPadding === undefined ? [4, 6] : bodyPadding;

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <Grid
      className="id--layout"
      templateAreas={`"nav header"
                      "nav main"
                      "nav footer"`}
      gridTemplateRows={`auto 1fr auto`}
      gridTemplateColumns={`auto 1fr`}
      height={"100vh"}
    >
      <GridItem area={"header"} as="header" boxShadow={"0px 3px 8px 2px rgb(0 0 0 / 5%)"} zIndex={100}>
        <NavBar menuIsVisible={isOpen} openMenu={() => onOpen()} />
      </GridItem>
      <GridItem area={"main"} as="main">
        <Body children={children} bodyPadding={bodyPaddingToUse} bgColor={bgColor} />
      </GridItem>
      <GridItem area={"nav"} as="aside">
        {/* <SideBar menuIsVisible={isOpen} closeMenu={() => onClose()} /> */}
      </GridItem>
      <GridItem area={"footer"} as="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
}
