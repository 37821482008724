import { useState } from "react";

import { HStack, VStack, Button, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { AppConfig } from "../../services/app-config-service";

interface Data {
  dt: Date;
  body: object;
}

const HomePage = () => {
  const [data, setData] = useState<Data | null>(null);

  function testApi() {
    fetch(`${AppConfig.apiUrl}/api/user-info`)
      .then((res) => res.json())
      .then((data) => {
        setData({
          dt: new Date(),
          body: data
        });
      });
  }

  return (
    <HStack w="full" h="calc(100vh - 170px)" alignItems="stretch" overflow={"hidden"}>
      <Helmet>
        <title>Testing 123</title>
      </Helmet>
      <VStack>
        <Button onClick={testApi}>Test API</Button>
        {data && (
          <>
            <Text>{data.dt.toISOString()}</Text>
            <Text>{JSON.stringify(data.body)}</Text>
          </>
        )}
      </VStack>
    </HStack>
  );
};

export default HomePage;
